body {
  @apply antialiased text-offBlack;
}


.generalGrid {
  @apply md:grid grid-cols-12 gap-5;
}

.button {
  @apply py-3 px-5 rounded-full bg-btDarkPink text-btPink uppercase font-gilbert font-bold w-max;
}

.webkit-fill {
  height: 100vh;
  height: 100cqh;
}

/* purgecss start ignore */

.twic-blur, .twic-loading {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
}

  .twic-done {
    opacity: 1;
  }


  :where(media-player[data-view-type='video']:not([data-fullscreen])) :where(media-outlet, video, media-poster, div[part='scrim']) {
    @apply rounded-[0px] border-none border-transparent;
  }

/* purgecss end ignore */